import RequestForm from '@/components/RequestForm.vue'

export default {
  name: 'Create',
  components: { RequestForm },
  props: {
    type: String,
    guestAccountUsername: String
  }
}
