import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VLayout,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"md":"8","offset-md":"2"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("New Request")]),_c(VCardText,[_c('request-form',{attrs:{"type":_vm.type ? _vm.type : 'ACCOUNT_CREATION',"guestAccountUsername":_vm.guestAccountUsername}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }